<template>
  <ItemsListCard :items="items" :displayVariantsDialogAuto="true" :isStockInfoAvailable="true"></ItemsListCard>
</template>

<script>
import ItemsListCard from "@/components/ItemsListCard.vue";
import Vue from "vue";

export default {
  name: "CatalogGroupDetail",
  components: { ItemsListCard },
  data() {
    return {
      groupIdentifier: null,
      items: [],
      currentOffset: 0,
      limit: 50,
    };
  },
  methods: {
    getContentToDisplay() {
      this.groupIdentifier = parseInt(this.$route.params.detail);
      let familyId = this.groupIdentifier;
      let offset = this.currentOffset;
      let limit = this.limit;

      let actionType = "getContentToDisplay-Before";
      let actionValue = "";
      let actionData = { familyId: familyId, offset: offset, limit: limit };
      this.$store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
      });

      this.$store
        .dispatch("items/getFamilyItems", { familyId, offset, limit })
        .then((result) => {
          if (result.length > 0) {
            this.items = this.items.concat(result);
            this.currentOffset += result.length;
          }
          let actionType = "getContentToDisplay-After";
          let actionValue = "";
          let actionData = {};
          this.$store.dispatch("dataLog/setData", {
            actionType,
            actionValue,
            actionData,
          });
          this.$store.dispatch("loader/setLoading", false);
        })
        .catch((error) => {
          Vue.$log.debug(error.message);

          let actionType = "getContentToDisplay-AfterERROR";
          let actionValue = "";
          let actionData = error.message;
          this.$store.dispatch("dataLog/setData", {
            actionType,
            actionValue,
            actionData,
          });

          this.$store.dispatch("loader/setLoading", false);
        });
    },
  },
  watch: {
    /**
     * https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
     * To react to params changes in the same component, it is necessary to watch the $route object
     * @param to
     * @param from
     */
    $route(to, from) {
      var toGroupIdentifier = to.params.detail;
      var fromGroupIdentifier = from.params.detail;

      if (!fromGroupIdentifier || toGroupIdentifier !== fromGroupIdentifier) {
        this.items = [];
        this.currentOffset = 0;
        this.getContentToDisplay();
      }
    },
  },
  mounted() {
    this.getContentToDisplay();

    window.onscroll = () => {
      if (document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight) {
        // Try to find more results and put loading layer only if the number of
        // results is multiple of the limit
        if (this.items.length % this.limit === 0) {
          let actionType = "getContentToDisplay-mounted";
          let actionValue = "";
          let actionData = {};
          this.$store.dispatch("dataLog/setData", {
            actionType,
            actionValue,
            actionData,
          });
          this.$store.dispatch("loader/setLoading", true);
          //@modified ana.castro 2024.02.29
          //Não nos lembramos exatamente porque precisamos de implementar isto. Mas estamos com um erro no SFCosta
          // com origem nesta linha.
          //O erro: em famílias com poucos artigos (1, 2, 3) os artigos são apresentados em duplicado.
          //Decidimos, portanto, comentar esta linha e deixar este extenso comentário para o caso de nos depararmos com
          // algum bug que tenha como origem esta acção.
          //modified @daniel.silva 2024.03.18
          // tivemos de reverter pois não estava a carregar as novas páginas.
          this.getContentToDisplay();
        }
      }
    };
  },
};
</script>

<style scoped></style>
